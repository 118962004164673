import React from 'react';
import Resources from '../Resources/Resources';

const ConversationalAiAssistants = () => {
    let resource = {
        title: 'Asistente Conversacional IA',
        url: '/conversational_ai_assistants',
        colsHeader: [
            { field: 'name', header: 'Nombre'},
            { field: 'gateway', header: 'Gateway'},
            { field: 'action', header: 'Acciones' }
        ],
        params: {
        },
        action: {
            add: '/new',
            show: '/conversational_ai_assistants/',
            edit: '/edit',
            delete: false
        },
        urlTable: [
            '/conversational_ai_assistants',
            '/conversational_ai_assistants/new',
            '^\\/conversational_ai_assistants\\/\\d+$',
            '^\\/conversational_ai_assistants\\/\\d+\\/edit$'
        ]
    };

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                        params={resource.params}   action={resource.action} urlTable={resource.urlTable} />);
}

export default ConversationalAiAssistants;