import React, {Fragment, useState, useMemo, useRef, useContext, useEffect} from 'react';
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from "primereact/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {markAsSeen, markAsArchived, toggleAssign, markAsPinup, markAsArchivedAndClean} from "../../../utils/messenger";
import {getFormattedDateLuxon} from "../../../utils/utils";
import { CompanyContext } from '../../../context/companyContext';
import {MessageContext} from "../../../context/messageContext";

import { WHATSAPP } from "../../../shared/channelTypes";
import CustomTag from '../../../UI/CustomTag';
import './ConversationListItem.css';

import ConversationPreview from '../ConversationPreview';
import jwtDecode from "jwt-decode";

import Avatar from 'react-avatar';

import { removeNumbersAndSpecialCharacters, avatarColors } from '../../../utils/utils';
import { markCurrentUser, assignConversation, unmarkRecentlyTransfered } from "../../../utils/users";
import UserGroupSelector from "../UserGroupSelector/UserGroupSelector"
import TransferredLabel from '../TransferredLabel/TransferredLabel';

export default function ConversationListItem(props) {
    const [toggleMoreTags, setToggleMoreTags] = useState(false);
    const refMenu = useRef(null);
    const refMenuClean = useRef(null);
    const refUserGroupMenu = useRef(null);
    const refToast = useRef(null);
    const [showOptions, setShowOptions] = useState(false);
    const [contextMenuIsVisible, setContextMenuIsVisible] = useState(false);
    const [showSelectUser, setShowSelectUser] = useState(false);
    const [showSelectGroup, setShowSelectGroup] = useState(false);
    const [itemUserGroupMenu, setItemUserGroupMenu] = useState([]);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const {name, text, client, mimetype, message_type , sent, read, delivered, type, created,  id} = props.data;
    const [showTransferredLabel, setShowTransferredLabel] = useState(false);

    const company_context = useContext(CompanyContext);

    const fulltime = useMemo(() => {
        return getFormattedDateLuxon(created, company_context.providerTimezone);
    }, [id]);

    const message_context = useContext(MessageContext);
    const [dropdownUserGroupPosition, setDropdownUserGroupPosition] = useState({ x: 0, y: 0 });
    const [contextMenuEvent, setContextMenuEvent] = useState(null);
    const [dropdownUserGroupStyle, setDropdownUserGroupStyle] = useState({});
    const [userGroupCancelButtonStyle, setUserGroupCancelButtonStyle] = useState({});

    const hideMenuClean = (event) => {
        const e = contextMenuEvent;
        if (e) {
            refMenuClean.current.show(e);
            refMenu.current.hide(e);
        }
    }

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    const user_tag = client.conversation && (
        <CustomTag
            iconComponent={<FontAwesomeIcon icon={'fa-regular fa-user'}></FontAwesomeIcon>}
            text={' ' + markCurrentUser([client.conversation.user])[0].username}
            color={'#7591cb'}
        />
    );

    const group_tag = client.conversation?.group &&
        (
            <CustomTag
                iconComponent={<FontAwesomeIcon icon={'fa-regular fa-people-group'}></FontAwesomeIcon>}
                text={' ' + client.conversation.group.name}
            />
        );

    const InitialCustomTags = () => {

        let tags = useMemo(() => {
            return client.tags.sort(function (a, b){
                return a.name.length - b.name.length;
            });
        }, [client.tags, client.id]);

        let firstRowTags = tags.slice(0, 1);

        const first_tag = firstRowTags.map((tag, index) => {
            const tag_color = tag.color === '' ? 'green' : tag.color;
            return (
              <CustomTag
                key={index}
                iconComponent={<FontAwesomeIcon icon={'fa-regular fa-tag'} />}
                color={tag_color}
                text={tag.name}
                tooltip={tag.name}
              />
            );
          });


        let secondRowTags = tags.slice(1, 2);

        const second_tag = secondRowTags.map((tag, index) => {
            const tag_color = tag.color === '' ? 'green' : tag.color;
            return (
              <CustomTag
                key={index}
                iconComponent={<FontAwesomeIcon icon={'fa-regular fa-tag'} />}
                color={tag_color}
                text={tag.name}
                tooltip={tag.name}
              />
            );
          });

        const anyTagExists = group_tag || user_tag || first_tag.length > 0 || second_tag.length > 0;

        return (
            <Fragment>
                <div className={`grid ${anyTagExists ? 'mt-1' : ''}`}>
                    {group_tag}
                    {user_tag}
                    {first_tag}
                    {second_tag}
                </div>
            </Fragment>
        );
    }

    const MoreTags = () => {
        let form = null;

        let tags = useMemo(() => {
            return client.tags.sort(function(a, b){
                return a.name.length - b.name.length;
            });
        }, [client.tags, client.id]);

        let client_custom_tags = [...tags]

        if (toggleMoreTags){
            client_custom_tags = client_custom_tags.slice(2);

            let client_tags = client_custom_tags.map((tag, index) => {
                const tooltipText = tag.name.length > 35 ? tag.name : '';
                const tag_color = tag.color === '' ? 'green' : tag.color;
                return (
                  <CustomTag
                    key={index}
                    iconComponent={<FontAwesomeIcon icon={'fa-regular fa-tag'} />}
                    color={tag_color}
                    text={tag.name}
                    tooltip={tooltipText}
                  />
                );
              });

            form = (
                <div className="grid mt-auto">
                    {client_tags}
                </div>
            );
        }
        return form;
    }

    const Fulltime = () => {
        return (
            id && (
                <div className='full-time p-as-center' style={{ color: (client.unread_messages === true ? "#31D1AEE5" : '') }}>
                    {fulltime}
                </div>
            )
        )
    }

    const clientHasMoreTags = () => {
        let form = null;
        let tags = client.tags;
        let total_tags = tags.length;

        if (total_tags > 2) {
            let icon = toggleMoreTags ? 'pi pi-angle-double-up' : 'pi pi-angle-double-down';
            form = (
                <div className="more-tags">
                    <span onClick={(e) => setToggleMoreTags(!toggleMoreTags)}>
                        <i className={icon}></i>
                    </span>
                </div>
            );
        }
        return form;
    }

    const iconClientHasMoreTags = () => {
        return (
            <span
                style={{ backgroundColor: 'transparent' }}
                className="badges p-badge p-badge-info p-ml-2"
                onClick={(e) => e.stopPropagation()}
            >
                {clientHasMoreTags()}
            </span>
        )
    }

    const pinup = () => {
        return client.pinup && (
            !(company_context.privateInbox && props.supervisor) && (
                <div className="p-as-center"> <FontAwesomeIcon icon={'thumbtack'}></FontAwesomeIcon> </div>
            )
        )
    }

    useEffect(() => {
        const usersListWithCurrentUserMarked = markCurrentUser(message_context.users);
        setUsers(usersListWithCurrentUserMarked);
        setGroups(message_context.groups)
        let usersList =  {
            label: 'Usuarios',
            items:
                [
                    {
                        template:
                            <UserGroupSelector
                                client={client}
                                users={usersListWithCurrentUserMarked}
                                groups={[]}
                                showSelectUser={true}
                                showSelectGroup={false}
                                onUserGroupChangeHandler={onUserGroupChangeHandler}
                                hideUserGroupList={hideUserGroupList}
                                cancelButton={false}
                                onClick={(e) => {e.stopPropagation()}}
                            />,
                    }
                ],
                command: (e) => e.originalEvent.stopPropagation()
            }
            let groupsList = {
            label: 'Grupos',
            items:
                [
                    {
                        template:
                            <UserGroupSelector
                                client={client}
                                users={[]}
                                groups={message_context.groups}
                                showSelectUser={false}
                                showSelectGroup={true}
                                onUserGroupChangeHandler={onUserGroupChangeHandler}
                                hideUserGroupList={hideUserGroupList}
                                cancelButton={false}
                                onClick={(e) => {e.stopPropagation()}}
                            />,
                    }
                ],
                command: (e) => e.originalEvent.stopPropagation()
            }
            if (isMobile()) {
                usersList =  {
                    label: 'Usuarios',
                    command: () => {
                        setShowSelectUser(true);
                    }
                }
                groupsList = {
                    label: 'Grupos',
                    command: () => {
                        setShowSelectGroup(true);
                    }
                }
            }
            setItemUserGroupMenu([usersList, groupsList]);
      }, [message_context.groups, message_context.users])

    useEffect(() => {
        setDropdownUserGroupStyle({ left: `${dropdownUserGroupPosition.x }px`, top: `${dropdownUserGroupPosition.y }px` })
        setUserGroupCancelButtonStyle({ left: `${dropdownUserGroupPosition.x + 80}px`, top: `${dropdownUserGroupPosition.y - 30}px` })
    }, [dropdownUserGroupPosition])

    const hideUserGroupList = (event) => {
        if (showSelectUser || showSelectGroup) {
            setShowSelectUser(false);
            setShowSelectGroup(false);
        } else {
            const e = contextMenuEvent;
            if (e) {
                refUserGroupMenu.current.show(e);
                setDropdownUserGroupPosition({
                    x: e.clientX,
                    y: e.clientY,
                });
            }
        }
    }

      const onUserGroupChangeHandler = async (data) => {
        let params = {
          data: {}
        }
        if (data.username !== undefined){
          params['data']['user_id'] = data.id
        } else {
          params['data']['group_id'] = data.id
        }
        params['data']['recipient'] = props.data.client.contacts[0].value;
        params['data']['country_prefix'] = props.data.client.contacts[0].country_prefix;
        params['data']['channel_id'] = WHATSAPP;
        try{
            await assignConversation(params, client.id)
        }catch(error){
            refToast.current.show({severity: 'error', summary: 'Error al asignar conversación', detail: 'Ocurrió un error al asignar conversación'});
        }
        setShowSelectGroup(false);
        setShowSelectUser(false);
      }

    const userGroupList = (
            <div onClick={(e) => e.stopPropagation()}>
                <UserGroupSelector
                    client={client}
                    users={users}
                    groups={groups}
                    showSelectUser={showSelectUser}
                    showSelectGroup={showSelectGroup}
                    onUserGroupChangeHandler={onUserGroupChangeHandler}
                    hideUserGroupList={hideUserGroupList}
                    styleDropdown={dropdownUserGroupStyle}
                    styleCancelButton={userGroupCancelButtonStyle}
                    />
            </div>
    );

    const formatBold = (body) => {
        return <b>{body.replace(/\*(.*?)\*/g, "$1")}</b>
    }
    const formatItalic = (body) => {
        return <i>{body.replace(/_(.*?)_/g, "$1")}</i>
    }

    const formatBody = (body) => {
        if(body.match(/\*(.*?)\*/g)){
            body = formatBold(body)
        }
        else if (body.match(/_(.*?)_/g)){
            body = formatItalic(body)
        }
        return body
    }

    const showIconOptions =
        <Fragment>
            <div className="p-as-center">
                <button className="p-link" onClick={(e) => handleContextMenuVisibility(e)}>
                    <FontAwesomeIcon icon={"chevron-down"} size={"xs"} />
                </button>
            </div>
        </Fragment>
        ;

    const iconOptions = () => {
        return props.selectedTab !== 2 && (
            <span
                style={{ backgroundColor: 'transparent' }}
                className="badges p-badge p-badge-info p-ml-2"
                onClick={(e) => e.stopPropagation()}
            >
                {showIconOptions}
            </span>
        )
    }

    const renderAction = () => {
        const { unread_messages, counter_unread_messages } = client;
        return (
            <div className="grid">
                <div className="conversation-title col-8 md-9">
                    <h1 className="conversation-list-item-name">
                        {name}
                    </h1>
                </div>
                <div className="col-4 md-3 p-nogutter">
                    <div className="flex justify-content-end p-nogutter">
                        {full_time}
                        {!unread_messages ? null : (
                            <span className="p-badge p-badge-info ml-2" style={{ backgroundColor: "#46d6b7", color: "#fff" }}>
                                {counter_unread_messages == null ? 1 : counter_unread_messages === 0 ? 1 : counter_unread_messages}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const active_element = props.conversationActive ? 'active' : '';

    const initial_tags = InitialCustomTags();
    const more_tags = MoreTags();
    const full_time = Fulltime();

    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);

    let itemsClean = [
        {
           label:`Limpiar sólo operador`,
           command:()=>{ markAsArchivedAndClean(true, true, false, client.id); }
        },
        {
           label:`Limpiar operador y etiquetas`,
           command:()=>{ markAsArchivedAndClean(true, true, true, client.id); }
        },
    ];

    let items = [
        {
           label:`Marcar como ${client.unread_messages ? 'leído' : 'no leído'}`,
           command:()=>{ markAsSeen(client.unread_messages ? true : false, client.id); }
        },
        {
           label:`Marcar como ${client.archived ? 'no archivado' : 'archivado'}`,
           command:()=>{ markAsArchived(client.archived ? false : true, client.id); }
        },
        {
           label: <div onClick={hideMenuClean}> {`Marcar como archivado y limpiar`} </div>,
        },
        {
           label:`Marcar como ${client.pinup ? 'no fijado' : 'fijado'}`,
           command:()=>{ markAsPinup(client.pinup ? false : true, client.id); }
        },
        {
            label:`Asignar usuario/grupo`,
            command:(e)=>{ hideUserGroupList(e) }
         },
     ];

    if (company_context.privateInbox && props.supervisor) {
        items = [items[0],items[1],items[2], items[4]]
    }

    if (client.archived) {
        items = [items[1]]
    }

    if (client.conversation !== undefined){
        items.push(
            {
                label: client.conversation === null ? 'Asignarmelo' : 'Desasignar',
                command:()=>{ toggleAssign(client.id, decoded_jwt.user_claims.user_id, true, props); }
            }
        )
    }

    const contextMenu = (<ContextMenu className='menu-items' model={items} ref={refMenu}/>);
    const contextMenuClean = (<ContextMenu className='' model={itemsClean} ref={refMenuClean}/>);
    const contextMenuUserGroupList = (
        <ContextMenu model={itemUserGroupMenu} ref={refUserGroupMenu} />
     );

    const handleContextMenuVisibility = (e) => {
        if (props.selectedTab === 2) {
            e.preventDefault();
            return;
        }
        if (contextMenuIsVisible) {
            refMenu.current.hide(e);
            refUserGroupMenu.current.hide(e);
            setContextMenuIsVisible(false);
            setContextMenuEvent(null);
        } else {
            refMenu.current.show(e)
            setContextMenuEvent(e);
            setContextMenuIsVisible(true);
        }
        e.preventDefault();
    }

    useEffect(() => {
        const userId = decoded_jwt.user_claims.user_id
        if (props.data?.client?.conversation?.recently_transferred && props.data?.client?.conversation?.user?.id === userId) {
            setShowTransferredLabel(true);
        } else {
            setShowTransferredLabel(false);
        }
    } , [props.data?.client?.conversation?.recently_transferred,
        props.data?.client?.conversation?.user]);

    const handleClick = () => {
        props.onClick();
        const userId = decoded_jwt.user_claims.user_id
        if (props.data?.client?.conversation?.recently_transferred && props.data?.client?.conversation?.user?.id === userId) {
            unmarkRecentlyTransfered(props.data.client.conversation.id);
            setShowTransferredLabel(false);
        }
    }

    return (
        <Fragment>
            <div className={`conversation-list-item ${active_element}`} onClick={handleClick} onContextMenu={(e) => handleContextMenuVisibility(e)} onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                <div className='flex flex-row transferred-item'>
                    {showTransferredLabel && <TransferredLabel />}
                </div>
                <div className='flex flex-row card-item-box'>
                    {props.selectedTab !== 2 && (
                        <div>
                            {contextMenu}
                            {contextMenuClean}
                            {contextMenuUserGroupList}
                            {isMobile() && userGroupList}
                        </div>
                    )}
                    <div className="flex flex-column p-ac-start conversation-list-avatar-container">
                        <Avatar colors={avatarColors} maxInitials={1} className="conversation-photo p-ac-center" name={removeNumbersAndSpecialCharacters(name)} round={true} size="60" />
                        {iconClientHasMoreTags()}
                    </div>
                    <div className="conversation-info">
                        {renderAction()}
                        <div className="grid">
                            <div className="col-8 md-9">
                                <div className="conversation-snippet" style={{ fontWeight: (client.unread_messages === true && "bold"), color: (client.unread_messages === true && "#77b8ff") }}>
                                    <ConversationPreview sent={sent} read={read} type={type} delivered={delivered} mimetype={mimetype} data={formatBody(text)} unread={client.unread_messages}
                                        messageType={message_type} />
                                </div>
                            </div>
                            <div className="col-4 md-3 flex justify-content-end">
                                {pinup()}
                                {iconOptions()}
                            </div>
                        </div>
                        {initial_tags}
                        {more_tags}
                    </div>
                </div>
            </div>
            <Toast ref={refToast} />
        </Fragment>
    );
}