import React from 'react';

const ResultNotMoreFount = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '10px',
        fontStyle: 'italic',
        color: 'gray',
      }}
    >
      No hay más resultados
    </div>
  );
}

export default ResultNotMoreFount;