import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { throttle, debounce } from "throttle-debounce";

import ApiLoan from '../../service/ApiLoanService';

import Templates from './Templates';
import TemplateForm from './TemplateForm';

const TemplateManager = (props) => {
    const [ templatesState, setTemplateState ] = useState({
        currentTemplate: null,
        templates: [],
        filteredTemplates:[],
        page: 1,
        filteredPage: 1,
        num_results: 0,
        action: 'list',
        loading: false,
        searchQuery: null,
        scrollRef: null,
        whatsappTemplateValue: props.businessIntegration ? (localStorage.getItem('gatewayWaba') === 'CLOUD-API') : false,
        businessIntegration: props.businessIntegration
    });
    const [hasMoreTemplate, setHasMoreTemplate] = useState(false);

    const [gateway, setGateway] = useState(null);

    const scrollContainer = useRef();

    useEffect(() => {
        const apiService = new ApiLoan();
        return apiService.getResources({
            url: '/profile'
        }).then(response => {
            setGateway(response.data.provider.gateway);
        });
    }, []);

    const getTemplates = () => {
        const apiService = new ApiLoan();

        let _filters =  [
            {
                name:'whatsapp_template',
                op: 'eq',
                val: templatesState.whatsappTemplateValue
            }
        ]
        if (props.businessIntegration){
            _filters.push({
                name:'business_integration',
                op: 'eq',
                val: props.businessIntegration
            })
        }

        apiService.getResources({
            url: '/templates',
            page: templatesState.page,
            filters: _filters,
            order_by: [{
                  "field": "id",
                  "direction": "desc"
            }]
        }).then(response => {
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    templates: [
                        ...prevState.templates,
                        ...response.data.objects
                    ],
                    page: response.data.total_pages <= templatesState.page ? response.data.page : response.data.page + 1,
                    num_results: response.data.num_results,
                    total_pages: response.data.total_pages
                }
            });
            templatesState.page < response.data.total_pages ? setHasMoreTemplate(true) : setHasMoreTemplate(false);
            props.onGetTemplates(response.data.objects);
        }).catch(() => {
            return;
        });
    };

    const getFilteredTemplates = (page, searchQuery, whatsappTemplateValue, conversationFilterRequest=null) => {
        const apiService = new ApiLoan();
        let params = {
            page: page,
            filters: [{
                name:'title',
                op: 'like',
                val: `%${searchQuery}%`
            },
            {
                name:'whatsapp_template',
                op: 'eq',
                val: whatsappTemplateValue
            }],
            cancelToken: conversationFilterRequest ? conversationFilterRequest.token : null
        }

        if (props.businessIntegration){
            params.filters.push({
                name:'business_integration',
                op: 'eq',
                val: props.businessIntegration
            })
        }
 
        apiService.getResources({
            url: '/templates',
            ...params
        }).then(response => {
            setTemplateState((prevState) => {
                const filteredTemplates = page <= 1 ? [...response.data.objects] : [...prevState.filteredTemplates, ...response.data.objects];
                return {
                    ...prevState,
                    filteredTemplates: filteredTemplates,
                    filteredPage: page
                }
            });
        }).catch(() => {
            return;
        });
    };

    const getFilteredTemplatesThrottled = useRef(throttle(600, getFilteredTemplates));
    const getFilteredTemplatesDebounced = useRef(debounce(500, getFilteredTemplates));

    useEffect(() => {
        getTemplates();
    }, [templatesState.page, templatesState.whatsappTemplateValue])

    useEffect(() => {
        const CancelTokenFilterRequest = axios.CancelToken;
        const filterRequest = CancelTokenFilterRequest.source();

        if (templatesState.searchQuery) {
          if (templatesState.searchQuery.length < 3) {
            getFilteredTemplatesThrottled.current(1, templatesState.searchQuery, templatesState.whatsappTemplateValue, filterRequest);
          } else {
            getFilteredTemplatesDebounced.current(1, templatesState.searchQuery, templatesState.whatsappTemplateValue, filterRequest);
          }
        }
        else {
          setTemplateState((prevState) => {
            return {
                ...prevState,
                filteredTemplates: [],
                filteredPage: 1
            }
          });
        } 
    
        return () => {
            filterRequest.cancel()
        }   
    }, [templatesState.searchQuery]);

    const handleSearchQuery = (event) => {
        const query = event.target.value;

        setTemplateState((prevState) => {
            return {
                ...prevState,
                searchQuery: query
            }
        });
    }

    const handleWhatsappTemplate = (event) => {
        setTemplateState((prevState) => {
            return {
                ...prevState,
                whatsappTemplateValue: event.target.value,
                templates: [],
                filteredTemplates: [],
                searchQuery: null,
                page: 1,
                filteredPage: 1
            }
        });
        props.onGetTemplates([]);
    }

    const selectTemplate = (content, header, footer, whatsapp_template, template_uuid, template_header_config, title, buttons) => {
        props.onTemplateSelection({content, header, footer, whatsapp_template, template_uuid, template_header_config, title, buttons });
        props.setShowDialog(false);
    }

    const showEditTemplateForm = (id) => {
        let template = templatesState.filteredTemplates.length > 0 ? templatesState.filteredTemplates : templatesState.templates;
        const selectedTemplate = template.filter((template) => {
            return template.id === id
        });
        if(selectedTemplate.length > 0){
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    currentTemplate: selectedTemplate.pop(),
                    action: 'edit',
                }
            });
        }
    } 

    const showCreateTemplateForm = () => {
        setTemplateState((prevState) => {
            return {
                ...prevState,
                action: 'create',
            }
        });
    }

    const deleteTemplate = (id) => {
        const apiService = new ApiLoan();
        apiService.deleteResource({
            url: '/templates',
            resource_id: id
        }).then(response => {
            const updatedTemplates = templatesState
                .templates.filter((template) => {
                        return template.id !== id;
                });

            const updatedFilteredTemplates = templatesState
                .filteredTemplates.filter((template) => {
                    return template.id !== id;
                });

            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    templates: updatedTemplates,
                    filteredTemplates: updatedFilteredTemplates
                }
            });

            props.onTemplateDelete({id: id})
        }).catch(error => {
            props.onTemplateError(error.response.data.message);
        })
    }

    const createTemplate = (template) => {
        setTemplateState((prevState) => {
            return {
                ...prevState,
                templates: [
                    template,
                    ...prevState.templates
                ],
                action: 'list',
            }
        });
        if (template.whatsapp_template){
            template['status'] = 'PENDING';
        }
        props.onTemplateCreate(template);
    }

    const editTemplate = (template) => {
        const updatedTemplates = templatesState.templates.map((tmp) => {
            if(tmp.id === template.id){
                return template;
            }
            return tmp;
        });

        const updatedFilteredTemplates = templatesState.filteredTemplates.map((tmp) => {
            if(tmp.id === template.id){
                return template;
            }
            return tmp;
        });

        setTemplateState((prevState) => {
            return {
                ...prevState,
                action: 'list',
                templates: updatedTemplates,
                filteredTemplates: updatedFilteredTemplates,
            }
        });

        props.onTemplateUpdate(template);
    }

    const goBack = () => {
        setTemplateState((prevState) => {
            return {
                ...prevState,
                currentTemplate: null,
                action: 'list',
            }
        });
    }

    let componentToShow = null;

    switch(templatesState.action){
        case 'list':
            componentToShow = (<Templates 
                showDialog={props.showDialog}
                setShowDialog={props.setShowDialog}
                scrollRef={scrollContainer}
                queryString={templatesState.searchQuery}
                onChangeQueryString={handleSearchQuery}
                onTemplateSelection={selectTemplate}
                onCloseModal={props.onCloseModal}
                templates={(templatesState.filteredTemplates.length > 0 && templatesState.filteredTemplates) || templatesState.templates}
                editTemplate={showEditTemplateForm}
                deleteTemplate={deleteTemplate}
                newTemplate={showCreateTemplateForm}
                gateway={gateway}
                whatsappTemplate={templatesState.whatsappTemplateValue}
                onChangeWhatsappTemplate={handleWhatsappTemplate}
                templatesState={templatesState}
                hasMoreTemplate={hasMoreTemplate}
                setTemplateState={setTemplateState}
                businessIntegration={props.businessIntegration}
            />);
            break;
        case 'create':
            componentToShow = (<TemplateForm showDialog={props.showDialog}
                                             setShowDialog={props.setShowDialog}
                                             gateway={gateway} onSubmit={createTemplate}
                                             headerTitle={'Crear respuesta guardada'}
                                             titleButton={'Crear'}
                                             onCloseModal={props.onCloseModal} onBack={goBack}
                                             canCreateCustomVariables={props.canCreateCustomVariables ? props.canCreateCustomVariables : false}
                                             businessIntegration={props.businessIntegration}
                                             action={templatesState.action} />);
            break;
        case 'edit':
            componentToShow = (<TemplateForm showDialog={props.showDialog}
                                             setShowDialog={props.setShowDialog}
                                             gateway={gateway} onSubmit={editTemplate}
                                             headerTitle={templatesState.currentTemplate.whatsapp_template ? 'Vista respuesta guardada ' :'Editar respuesta guardada'}
                                             titleButton={'Editar'}
                                             template={templatesState.currentTemplate}
                                             onCloseModal={props.onCloseModal} onBack={goBack}
                                             canCreateCustomVariables={props.canCreateCustomVariables ? props.canCreateCustomVariables : false}
                                             businessIntegration={props.businessIntegration}
                                             action={templatesState.action}/>);
            break;
        default:
            componentToShow = (<Templates 
                showDialog={props.showDialog}
                setShowDialog={props.setShowDialog}
                scrollRef={scrollContainer}
                queryString={templatesState.searchQuery}
                onChangeQueryString={handleSearchQuery}
                onTemplateSelection={selectTemplate}
                onCloseModal={props.onCloseModal}
                templates={templatesState.templates}
                editTemplate={showEditTemplateForm}
                deleteTemplate={deleteTemplate}
                gateway={gateway}
                whatsappTemplate={templatesState.whatsappTemplateValue}
                onChangeWhatsappTemplate={handleWhatsappTemplate}
                templatesState={templatesState}
                hasMoreTemplate={hasMoreTemplate}
                setTemplateState={setTemplateState}
            />);
            break;
    }
    
    return componentToShow;
}

export default TemplateManager;