import React, {useState, useEffect, useRef, Fragment} from 'react';
import ApiLoan from "../../../service/ApiLoanService";
import { InputText } from 'primereact/inputtext';
import {Toast} from "primereact/toast";
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import {InputNumber} from "primereact/inputnumber";

const ConversationalAIAssistantForm = (props) => {
    const [action, setAction] = useState('create');


    const initialState = {
        gateway: 'Dify',
        name: '',
        config: {
            url: '',
            token: '',
            conversation_counter: 10
        },
        active: true,

    };
    const [formData, setFormData] = useState(initialState);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const apiService = new ApiLoan();



    useEffect(() => {
        let edit = props.match.params.id !== undefined;
        if (edit){
            apiService.getResource({
                url: '/conversational_ai_assistants/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                let _config = JSON.parse(response.data.config);
                setFormData({
                    gateway: data.gateway,
                    name: data.name,
                    config: {
                        url: _config.url,
                        token: _config.token,
                        conversation_counter: _config.conversation_counter
                    },
                    active: data.active
                })
            }).catch(error => {
                refToast.current.show({severity: 'error', summary: 'IA Conversacional',
                    detail: 'Error al obtener la IA conversacional.'});
                setTimeout(() => {
                    props.history.push('/conversational_ai_assistants')
                }, 2000);
            }).finally(f => {
                setAction('edit');
                setLoading(false);
            });

        } else {
            setLoading(false)
        }
    }, [props.history, props.match.params.id]);


    const handleSubmit = () => {
        let url = '/conversational_ai_assistant';
        let detail = null;

        let api_request = null;
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id;
        }

        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            let message_action = action === 'create' ? 'creado' : 'editado';
            refToast.current.show({severity: 'success', summary: 'IA Conversacional',
                detail: `La IA conversacional se ha ${message_action} satisfactoriamente.`});
            setTimeout(() => {
                props.history.push('/conversational_ai_assistants')
            }, 1000);
        }).catch(error => {
            if (error?.response?.data) {
                setErrors(error?.response?.data);
                detail = 'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'IA Conversacional',
                detail: detail});
        });
    }

    const refToast = useRef(null);

    const onChangeHandler = (event) => {
        let field = event.target.id
        let value = event.target.value

        setFormData((prevState) => {
            return {
                ...prevState,
                [field]: value
            }
        });
    }

    const labelSubmit = action === 'create' ? 'Crear' : 'Guardar';

    const handleConfig = (field, value) => {
        let _config = {...formData.config}

        _config[field] = value;
        setFormData((prevState) => {
            return {
                ...prevState,
                config: _config
            }
        });
    }

    const configForm = () => {
        return (
            <Fragment>
                <div className="field col-3">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={formData.config.url}
                                   onChange={(e) => handleConfig('url', e.target.value)}/>
                        <label htmlFor="inputtext">URL</label>
                    </span>
                </div>
                <div className="field col-3">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={formData.config.token}
                                   onChange={(e) => handleConfig('token', e.target.value)}/>
                        <label htmlFor="inputtext">Token</label>
                    </span>
                </div>
                <div className="field col-2">
                    <span className="p-float-label">
                        <InputNumber id="inputtext" value={formData.config.conversation_counter}
                                   min={2} max={30}
                                   onValueChange={(e) => handleConfig('conversation_counter', e.value)}/>
                        <label htmlFor="inputtext">Límite máximo de mensajes</label>
                    </span>
                </div>
            </Fragment>
        )
    }

    let form = loading ? null : (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>IA Conversacional</strong></h1>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="flex p-fluid col-4 md:col-4">
                            <div className="field col-12">
                                <span className="p-float-label">
                                    <InputText id="inputtext" value={formData.gateway} disabled={true}/>
                                    <label htmlFor="inputtext">Servicio</label>
                                </span>
                                {errors?.gateway !== undefined ?
                                    <small style={{color: '#f44336'}}
                                           className="p-error">{errors?.name[0]}</small> : null
                                }
                            </div>
                            <div className="field col-10">
                                <span className="p-float-label">
                                    <InputText id="name" value={formData.name}
                                               onChange={(e) => onChangeHandler(e)}/>
                                    <label htmlFor="inputtext">Nombre</label>
                                </span>
                                {errors?.name !== undefined ?
                                    <small style={{color: '#f44336'}}
                                           className="p-error">{errors?.name[0]}</small> : null}
                            </div>

                            <div className={"field md:col-2"} style={{marginTop: '-1rem'}}>
                                <label htmlFor="switch">Habilitado</label>
                                <span className="p-float-label">
                                    <InputSwitch id="active" checked={formData.active}
                                                 onChange={(e) => onChangeHandler(e)}/>
                                </span>
                            </div>
                        </div>
                        <div className="field col-12">
                            <h5 className={''}>Configuración del servicio</h5>
                            <div className="flex field col-12 mt-4">
                                {configForm()}
                            </div>
                        </div>
                        <div className="field col mt-3">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <span className="p-float-label">
                                        <Button label={labelSubmit} className={"mt-4"}
                                                onClick={(e) => handleSubmit(e)}
                                                icon={props.formData !== null ? 'pi pi-pencil' : 'pi pi-plus'}/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return form;
}

export default ConversationalAIAssistantForm;