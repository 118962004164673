import React from 'react';

const LoadMoreResults = ({ loadingResult }) => {
  return (
    <div
      onClick={loadingResult}
      style={{
        textAlign: 'center',
        padding: '10px',
        fontStyle: 'italic',
        color: 'gray',
        cursor: 'pointer',
      }}
    >
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Cargar más resultados
        <i className="pi pi-angle-down" style={{ marginLeft: '5px' }}/>
      </span>
    </div>
  );
};

export default LoadMoreResults;